import {IFeatureImage, IPhoto} from './photo';

import {MediaPlayerSizeEnum} from '@pui/components/media-player';
import {ThemeColorPaletteEnum, ThemeNameEnum, ThemeTypographyEnum} from '@gds/styles';
import {AspectRatio} from '@px/util/aspect-ratio';
import {Covers} from '../enums/covers.enum';
import {ImageQuality} from '../enums/image-quality.enum';
import {SlideshowState} from '../enums/slideshow-state.enum';
import {IAudio, IJoinedAudio} from './audio.interface';
import {ITemplate} from './template.interface';

/**
 * Photographer area slideshow
 */
export interface ISlideshow<T = number> extends ISlideShowClient<T> {
  slug: string;
  id: T;
  build_video_estimated_time: number | null;
  build_video_4k_estimated_time: number | null;
  created: number;
  aspect_ratio: AspectRatio;
  is_disabled?: boolean;
}

export interface ISegment<T = number> {
  slug: string | null;
  beat_matching_template: ITemplate<T> | null;
  is_beatmatching: boolean;
  photos: IPhoto<T>[];
  audio_start: number;
  template: ITemplate<T>;
  order: number;
  has_manual_photos_order: boolean;
  is_published: boolean;
  current_sort: string;
  audio: IAudio<T>;
  id: T;
  audio_end: number;
  name: string | null;
}

export interface ICommonData<T = number> {
  video_background: string;
  is_email_me: boolean;
  is_audio_changed: boolean;
  embed_config: IEmbedConfig;
  featured_image: IFeatureImage<T>;
  password: string | null;
  download_options: ImageQuality[];
  is_view_button: boolean | null;
  call_to_action: ICallToAction;
  display_filenames: boolean;
  theme: Covers;
  focal_point: IFocalPoint | null;
  state: SlideshowState;
  download_pin: string;
  is_download_pin: boolean | null;
  event_date: number | null;
  is_favorites_allowed: boolean;
  is_email_registration_allowed: boolean;

  download_protected: boolean;
  is_download_button: boolean;

  created?: number;
  updated?: number;
}

export interface IEmbedConfig {
  centered: boolean;
  width: number;
  height: number;
}

export interface ICallToAction {
  enabled: boolean;
  button_name: string | null;
  external_link: string | null;
}

export interface IFocalPoint {
  y: number;
  x: number;
}

/**
 * Client area slideshow
 */
export interface ISlideShowClient<T = number> {
  name: string;
  unique_identifier: string;
  segments: ISegment<T>[];
  /**
   * User id of the original slideshow owner (for Sample Content only)
   */
  original_user_id: number;
  /**
   * The original collection url for check if is Published (for Sample Content only)
   */
  original_collection_url?: string;
  common_data: ICommonData<T>;
  encoded_name: string;
  video_1080p: string;
  video_4k: string;
  aspect_ratio: AspectRatio;
  meta_data: ISlideshowMetaData;
  joined_audio?: IJoinedAudio;
}

export interface ISlideshowMetaData {
  style_options: ISlideshowStyleOptions;
  is_people_detection_enabled?: boolean;
}

export interface ISlideshowStyleOptions {
  themeName: ThemeNameEnum;
  typography: ThemeTypographyEnum;
  colorPalette: ThemeColorPaletteEnum;
  playerSize: MediaPlayerSizeEnum;
}
