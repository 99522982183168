import {Injectable} from '@angular/core';
import {ISessionProviderService} from '../entities/session-provider-service.interface';

/**
 * Why?
 * The problem is that we have two user roles on psf-themes. And sessions are needed for each of the roles. But since the API of these services is similar, one interface is used to work with both services.
 */
@Injectable()
export class SessionProviderFacade {
  private service: ISessionProviderService | undefined;

  getSessionService(): ISessionProviderService | undefined {
    return this.service;
  }

  attach(sessionProviderFacade: ISessionProviderService): void {
    this.service = sessionProviderFacade;
  }

  detach(): void {
    this.service = undefined;
  }
}
