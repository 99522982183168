import {inject, Injectable, NgZone} from '@angular/core';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {
  combineLatest,
  filter,
  first,
  fromEvent,
  interval,
  map,
  merge,
  Observable,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs';
import dayjs from 'dayjs';
import {WINDOW_TOKEN} from '@px/cdk/window';

@Injectable()
export class CheckUpdatesService {
  private readonly window = inject(WINDOW_TOKEN);
  private readonly VERSION_IS_READY = 'VERSION_READY';
  private readonly updates = inject(SwUpdate);
  private readonly ngZone = inject(NgZone);

  private readonly ONE_HOUR_INTERVAL = dayjs().add(1, 'hour').diff(dayjs(), 'milliseconds');

  readonly updateIsReady$: Observable<true> = this.updates.versionUpdates.pipe(
    filter((event): event is VersionReadyEvent => event.type === this.VERSION_IS_READY),
    map(state => state.latestVersion.hash !== state.currentVersion.hash),
    filter((state: boolean): state is true => state)
  );

  initializeCheck(time?: number): Observable<boolean> {
    const interval$ = interval(time ?? this.ONE_HOUR_INTERVAL);

    return interval$.pipe(
      startWith(null),
      filter(() => this.window.navigator.onLine),
      switchMap(async () => {
        try {
          return await this.updates.checkForUpdate();
        } catch (err) {
          console.error('Failed to check for updates:', err);

          return false;
        }
      }),
      first(hasUpdates => hasUpdates)
    );
  }

  initializeReloadWhenHidden(): Observable<void> {
    const onTaskEmpty$ = merge(
      this.ngZone.onStable.pipe(map(() => true)),
      this.ngZone.onUnstable.pipe(map(() => false))
    );

    const isTabHidden$: Observable<boolean> = fromEvent(this.window, 'visibilitychange').pipe(
      map(() => this.window.document.hidden)
    );

    return this.updateIsReady$.pipe(
      take(1),
      switchMap(() => combineLatest([isTabHidden$, onTaskEmpty$])),
      filter((args: boolean[]) => args.every(item => item)),
      tap(() => {
        this.window.location.reload();
      }),
      map(() => undefined)
    );
  }
}
