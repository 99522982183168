import {Languages} from '@px/shared/env';

export interface IUser extends IUserClient {
  oauth_code_login: boolean;
  published_media_collections_present: number;
  video_download_client_enabled: boolean;
  transition_duration: number;
  display_name: string;
  first_seen: number;
  beacon_signature: string;
  total_media_collections_present: number;
  slide_show_count: number;
  total_sample_content_media_collections_present: number;
  total_published_sample_content_media_collections_present: number;
  user_plan_id: string;
  user_id: number;
  custom_domain: string;
  language?: Languages;
  user_plan_name: string;
  user_type_hash: string;
  created_at: number;
  user_is_tier_3: boolean;
  last_transition_id: number;
  tpp_shown: boolean;
  download_video_allowed: boolean;
  download_video_4k_allowed: boolean;
  email: string;
  user_px_id: number;
  customer_first_login: boolean;
  trial_user_first_login: boolean;
}

export interface IUserClient {
  custom_domain: string;
  website: string;
  is_sharpen: boolean;
  hash: string;
  ga_user_code: string;
  studio_name: string;
  business_logo: string;
  studio_logo: IStudioLogo;
  full_name: string;
  no_tracking: boolean;
  id: number;
  is_trial: boolean;
  email: string;
}

export interface IStudioLogo {
  light_logo?: string;
  dark_logo?: string;
}
