import {Exclude, instanceToInstance, instanceToPlain, plainToClass, Transform, Type} from 'class-transformer';
import dayjs, {Dayjs} from 'dayjs';
import {IAudio} from '../interfaces/audio.interface';
import {Template} from './template';
import {AudioCategoryId} from '../enums/audio-categoriy.enum';
import {AudioEnergyId} from '../enums/audio-energy.enum';
import {AudioChange} from './audio-change';

export class Audio implements IAudio {
  private static CUSTOM_CATEGORY = 'My Uploads';

  @Exclude()
  private slowTemplate?: Template;
  @Exclude()
  private uptempoTemplate?: Template;

  @Transform(({value}) => instanceToPlain(value), {toPlainOnly: true})
  @Type(() => Template)
  templates: Template[] = [];
  up_tempo: number | null = null;
  down_tempo: number | null = null;
  lyrics: string | null = null;
  energy: string | null = null;
  is_new = false;
  expires_at: number | null = null;
  genre: string | null = null;
  original_file_name = '';
  instrumental = false;
  is_favorite = false;
  song_title = '';
  audio_mp3 = '';
  audio_ogg = '';
  expired = false;
  id = -1;
  category = '';
  waveform_data: number[] = [];
  artist = '';
  length = 0;
  provider: string | null = null;
  round_added: string | null = null;
  category_id: AudioCategoryId = AudioCategoryId.MY_UPLOADS;
  energy_id: AudioEnergyId = AudioEnergyId.LOW;
  is_recently_used: number | null = null;

  @Exclude()
  duration: Dayjs = dayjs(0);

  get isCustom(): boolean {
    return this.category === Audio.CUSTOM_CATEGORY;
  }

  get durationFormat(): string {
    if (!this.duration) {
      this.duration = dayjs(Math.round((this.length * 1000) / 60 / 1000) * 1000);
    }

    return this.duration.format('mm:ss');
  }

  get slowInfo(): Template | undefined {
    if (!this.slowTemplate) {
      this.slowTemplate = this.templates.find(item => item.isSlow);
    }

    return this.slowTemplate;
  }

  get uptempoInfo(): Template | undefined {
    if (!this.uptempoTemplate) {
      this.uptempoTemplate = this.templates.find(item => item.isUptempo);
    }

    return this.uptempoTemplate;
  }

  markAsFavorite(): Audio {
    this.is_favorite = true;
    return instanceToInstance(this);
  }

  removeFromFavorites(): Audio {
    this.is_favorite = false;
    return instanceToInstance(this);
  }

  updateInfo(changes: AudioChange): Audio {
    return plainToClass(Audio, Object.assign(instanceToPlain(this), changes));
  }

  hasNoTitleOrArtist(): boolean {
    return !this.song_title || !this.artist;
  }

  getPlain(): IAudio {
    return instanceToPlain(this) as IAudio;
  }
}
