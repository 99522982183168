import {Languages} from '@px/shared/env';
import {ReminderStatus} from '../enums/reminder-status.enum';
import {IStudioLogo} from './user';
import {Primitive} from 'ts-essentials';

export interface IPreferences<T = Record<string, unknown>> {
  custom_domain?: string | null;
  display_name?: string | null;
  business_logo?: string | null;
  watermark?: IWatermark;
  studio_logo?: IStudioLogo;
  current_period_end?: number | Date | undefined;
  email?: string | null;
  full_name?: string | null;
  ga_code?: string | null;
  ga_code_allowed?: boolean;
  is_sharpen?: boolean;
  language?: Languages;
  plan_name?: string;
  reminder_status?: ReminderStatus;
  studio_name?: string;
  image_upload_hint_shown?: boolean;
  tpp_shown?: boolean;
  waveform_pin_enabled?: boolean;
  website?: string;
  last_chosen_video_background?: string;
  video_background_notification_shown?: boolean;
  editor_thumbnail_size?: string;
  cameron_goal_done?: boolean;
  segment_beat_matched_hint_shown?: boolean;
  first_bm_segment_created?: boolean;

  meta_data?: Partial<T>;
  ge_photos_ordering_hint_seen?: boolean;
  gs_mobile_preview_hint_seen?: boolean;
  ge_sections_upload_hint_seen?: boolean;
  last_chosen_color_scheme?: string;
  last_chosen_cover_preset?: string;
  last_chosen_image_grid_spacing_preset?: string;
  last_chosen_typography_set?: string;
  last_chosen_thumbnail_size_preset?: string;
  ge_sections_rearrange_hint_seen?: boolean;
}

export interface IWatermark {
  watermark_scale?: number;
  watermark_position?: number;
  watermark_path?: string;
}
